export const colors = {
  background: "#FAF7EB",
  modalDim: "rgba(1, 1, 1, 0.6)",
  brand900: "#47B561",
  brand600: "#639562",
  brand400: "#95BE8D",
  brand300: "#AFD89E",
  brand100: "#EBF8F1",
  subBrand900: "#DDBA40",
  subBrand700: "#F8E65A",
  subBrand600: "#FFDD86",
  subBrand400: "#C1B897",
  subBrand300: "#FFEAC5",
  subBrand100: "#DBD6C5",
  subBrand50: "#F0EBD8",
  grey900: "#242C35",
  grey600: "#434445",
  grey300: "#8B8B8B",
  grey200: "#A7A7A7",
  grey150: "#CFC9C9",
  grey100: "#D9D9D9",
  grey50: "#F2F2F1",
  black: "#000",
  white: "#fff",
  red: "#EF5533",
  green: "#639562",
};
